// src/pages/TermsOfService.js
import React, { useEffect } from 'react';
import { Container, Typography, Box, Divider, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import bannerImage from '../assets/images/hero-background.png'; // Add a banner image to your assets

function TermsOfService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {/* Banner Section */}
      <Box
        sx={{
          width: '100%',
          height: { xs: '200px', md: '300px' },
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
          mb: 4,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '10px 20px',
            borderRadius: '8px',
          }}
        >
          Terms of Service
        </Typography>
      </Box>

      {/* Main Content */}
      <Container maxWidth="md" sx={{ paddingY: 4 }}>
        {/* Introduction */}
        <Box>
          <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
            Welcome to SmokeAwaySolutions! These Terms of Service ("Terms") govern your access to and use of our website and services ("Services"). By accessing or using our Services, you agree to be bound by these Terms.
          </Typography>
        </Box>

        {/* Sections */}
        <Stack spacing={4}>
          {/* Section 1 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              1. General Information
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              SmokeAwaySolutions is a company based in Sweden, providing innovative smoke odor elimination devices. We ship our products worldwide, aiming to deliver them in a timely and efficient manner.
            </Typography>
          </Box>

          {/* Section 2 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              2. Use of Services
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              You agree to use our Services only for lawful purposes and in accordance with these Terms. You must be at least 18 years old to make a purchase on our website.
            </Typography>
          </Box>

          {/* Section 3 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              3. Orders and Shipping
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              All orders are subject to acceptance and availability. We reserve the right to refuse or cancel any order for any reason. We ship products worldwide; shipping times and costs may vary depending on your location.
            </Typography>
          </Box>

          {/* Section 4 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              4. Prices and Payment
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              All prices are listed in USD (United States Dollars) unless otherwise specified. Payment is due at the time of order. We use secure third-party payment processors to handle your payment information.
            </Typography>
          </Box>

          {/* Section 5 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              5. Returns and Refunds
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              If you are not satisfied with your purchase, you may return it within 30 days of receipt for a full refund, subject to our return policy. The product must be unused and in its original packaging.
            </Typography>
          </Box>

          {/* Section 6 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              6. Intellectual Property
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              All content on our website, including text, graphics, logos, and images, is the property of SmokeAwaySolutions or its content suppliers and is protected by international copyright laws.
            </Typography>
          </Box>

          {/* Section 7 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              7. Limitation of Liability
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              To the fullest extent permitted by law, SmokeAwaySolutions shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of our Services.
            </Typography>
          </Box>

          {/* Section 8 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              8. Governing Law
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              These Terms are governed by and construed in accordance with the laws of Sweden. Any disputes arising out of or relating to these Terms shall be resolved by the courts of Sweden.
            </Typography>
          </Box>

          {/* Section 9 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              9. Changes to Terms
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the Services after changes have been made constitutes your acceptance of the new Terms.
            </Typography>
          </Box>

          {/* Section 10 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              10. Contact Us
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              If you have any questions about these Terms, please contact us at{' '}
              <Typography
                component="a"
                href="mailto:SmokeAwaySolutions@gmail.com"
                color="primary"
                sx={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                SmokeAwaySolutions@gmail.com
              </Typography>
              .
            </Typography>
          </Box>
        </Stack>
      </Container>
    </motion.div>
  );
}

export default TermsOfService;
