import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import kickstarterLogo from '../assets/images/tq0sfld-kickstarter-logo-green.avif';

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

function KickstarterBanner() {
  const numItems = 250;
  const containerRef = useRef(null);
  const [duration, setDuration] = useState(120); // Default duration

  useEffect(() => {
    const containerWidth = containerRef.current.scrollWidth;
    const speed =50; // pixels per second
    const calculatedDuration = containerWidth / speed;
    setDuration(calculatedDuration);
  }, [numItems]);

  return (
    <Box
      component="a"
      href="https://www.kickstarter.com/profile/smokeawaysolutions"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Support us on Kickstarter"
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '50px', md: '60px' },
        backgroundImage:
          'repeating-linear-gradient(45deg, #FFD700, #FFD700 20px, #000000 20px, #000000 40px)',
        transform: 'skewY(0deg)',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Yellow Stripe Overlay in the Middle */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          width: '100%',
          height: '70%',
          backgroundColor: '#FFD700',
          transform: 'translateY(-50%) skewY(0deg)',
          zIndex: 1,
          opacity: 0.9,
        }}
      />
      {/* Content Container */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          whiteSpace: 'nowrap',
          animation: `${scroll} ${duration}s linear infinite`,
          zIndex: 10,
        }}
        ref={containerRef}
      >
        {/* Duplicate the content */}
        {[...Array(2)].map((_, idx) => (
          <Box
            key={idx}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {Array.from({ length: numItems }).map((_, idx) => (
              <React.Fragment key={idx}>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 0.5,
                    fontWeight: 'bold',
                    color: '#04ce78',
                    fontFamily: '"Frankfurter", Poppins, Roboto, Arial, sans-serif',
                    textDecoration: 'underline',
                    textDecorationColor: '#04ce78',
                    fontSize: { xs: '1.2rem', md: '1.4rem' },
                  }}
                >
                  Support us on
                </Typography>
                <img
                  src={kickstarterLogo}
                  alt="Kickstarter Logo"
                  style={{ height: '16px', marginRight: '20px' }}
                  loading="lazy"
                />
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default KickstarterBanner;
