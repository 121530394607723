// src/pages/PrivacyPolicy.js
import React, { useEffect } from 'react';
import { Container, Typography, Box, Divider, Stack, Link as MuiLink } from '@mui/material';
import { motion } from 'framer-motion';
import bannerImage from '../assets/images/hero-background.png'; // Add a relevant banner image to your assets

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {/* Banner Section */}
      <Box
        sx={{
          width: '100%',
          height: { xs: '200px', md: '300px' },
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
          mb: 4,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '10px 20px',
            borderRadius: '8px',
          }}
        >
          Privacy Policy
        </Typography>
      </Box>

      {/* Main Content */}
      <Container maxWidth="md" sx={{ paddingY: 4 }}>
        {/* Introduction */}
        <Box>
          <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
            At SmokeAwaySolutions, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website.
          </Typography>
        </Box>

        {/* Sections */}
        <Stack spacing={4}>
          {/* Section 1 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              1. Information We Collect
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              We may collect personal information such as your name, email address, and contact details when you voluntarily provide it to us, for example, when making a purchase or signing up for our newsletter.
            </Typography>
          </Box>

          {/* Section 2 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              2. Use of Collected Information
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              Any personal data collected is used solely for marketing purposes on this website. This includes sending promotional emails, updates, and special offers that may be of interest to you.
            </Typography>
          </Box>

          {/* Section 3 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              3. Data Sharing and Disclosure
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              We do not share, sell, rent, or disclose your personal information to any third parties unless required by law.
            </Typography>
          </Box>

          {/* Section 4 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              4. Cookies and Tracking Technologies
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              Our website may use cookies and similar tracking technologies to enhance your browsing experience. These technologies help us understand your preferences and provide you with a more personalized experience.
            </Typography>
          </Box>

          {/* Section 5 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              5. Data Security
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
            </Typography>
          </Box>

          {/* Section 6 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              6. Your Rights
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              You have the right to access, correct, or delete your personal information held by us. If you wish to exercise these rights, please contact us at{' '}
              <MuiLink
                href="mailto:SmokeAwaySolutions@gmail.com"
                color="primary"
                sx={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                SmokeAwaySolutions@gmail.com
              </MuiLink>
              .
            </Typography>
          </Box>

          {/* Section 7 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              7. Changes to This Privacy Policy
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
            </Typography>
          </Box>

          {/* Section 8 */}
          <Box>
            <Typography variant="h4" component="h2" gutterBottom color="primary">
              8. Contact Us
            </Typography>
            <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
            <Typography variant="body1" paragraph color="text.secondary" sx={{ lineHeight: 1.8 }}>
              If you have any questions or concerns about our Privacy Policy, please contact us at{' '}
              <MuiLink
                href="mailto:SmokeAwaySolutions@gmail.com"
                color="primary"
                sx={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                SmokeAwaySolutions@gmail.com
              </MuiLink>
              .
            </Typography>
          </Box>
        </Stack>
      </Container>
    </motion.div>
  );
}

export default PrivacyPolicy;
