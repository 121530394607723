// src/pages/ContactUs.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  Divider,
  Stack,
} from '@mui/material';
import { motion } from 'framer-motion';
import contactBanner from '../assets/images/hero-background.png'; // Add a relevant banner image to your assets

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    error: false,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Here you can integrate with a backend service or API to handle form submissions
    // For demonstration, we'll just simulate a successful submission

    // Simple validation
    if (formData.name && formData.email && formData.message) {
      // Simulate success
      setFormStatus({
        submitted: true,
        success: true,
        error: false,
      });

      // Reset form
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } else {
      // Simulate error
      setFormStatus({
        submitted: true,
        success: false,
        error: true,
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* Banner Section */}
      <Box
        sx={{
          width: '100%',
          height: { xs: '200px', md: '300px' },
          backgroundImage: `url(${contactBanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
          mb: 4,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '10px 20px',
            borderRadius: '8px',
          }}
        >
          Contact Us
        </Typography>
      </Box>

      {/* Main Content */}
      <Container maxWidth="sm" sx={{ paddingY: 4 }}>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          If you have any questions or suggestions for special builds of the Odorless Tube, feel free to contact us here.
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
          <Stack spacing={3}>
            {/* Success Alert */}
            {formStatus.submitted && formStatus.success && (
              <Alert severity="success" sx={{ mb: 2, borderRadius: 2 }}>
                Your message has been sent successfully!
              </Alert>
            )}

            {/* Error Alert */}
            {formStatus.submitted && formStatus.error && (
              <Alert severity="error" sx={{ mb: 2, borderRadius: 2 }}>
                There was an error sending your message. Please ensure all fields are filled out correctly.
              </Alert>
            )}

            {/* Name Field */}
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              placeholder="Your Name"
              variant="outlined"
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
            />

            {/* Email Field */}
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              placeholder="your.email@example.com"
              variant="outlined"
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
            />

            {/* Message Field */}
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              required
              multiline
              rows={5}
              placeholder="Your message..."
              variant="outlined"
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
            />

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                paddingY: 1.5,
                fontSize: '1rem',
                fontWeight: 600,
                textTransform: 'none',
                borderRadius: 2,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s, transform 0.3s',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                },
              }}
            >
              Send Message
            </Button>
          </Stack>
        </Box>
      </Container>
    </motion.div>
  );
}

export default ContactUs;
