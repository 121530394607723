import React from 'react';
import { motion } from 'framer-motion';
import { Container, Typography, Box, Link, List, ListItem, ListItemText } from '@mui/material';

import imageIndoorAirQuality from '../../assets/images/blog/air-quality-living-room.png';

export function BlogPostIndoorAirQuality() {
  return (
    <Container maxWidth="md" sx={{ paddingY: 8 }}>
      {/* Meta Description */}
      {/* Meta Description: Discover why maintaining good indoor air quality is crucial for your health and well-being, and learn effective ways to improve it. */}

      {/* Blog Title */}
      <Typography variant="h4" component="h1" gutterBottom>
        The Importance of Indoor Air Quality: A Guide to Healthier Living
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Did you know that we spend approximately 90% of our time indoors? With so much of our lives happening inside our homes, offices, and other buildings, maintaining good indoor air quality (IAQ) is more important than ever. Poor IAQ can lead to a range of health issues, from minor irritations to serious diseases. In this blog post, we'll explore why indoor air quality matters and how you can improve it for a healthier, happier life.
      </Typography>

      {/* Image Suggestion */}
      {/* Insert an image representing indoor air quality */}
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <img
          src={imageIndoorAirQuality}
          alt="Indoor Air Quality"
          style={{ maxWidth: '50%', height: 'auto', borderRadius: '30px' }}
        />
      </Box>

      {/* Subheading 1 */}
      <Typography variant="h5" component="h2" gutterBottom>
        Why Indoor Air Quality Matters
      </Typography>
      <Typography variant="body1" paragraph>
        Indoor air quality refers to the condition of the air inside buildings, especially as it relates to the health and comfort of building occupants. Poor IAQ has been linked to a variety of health problems, including:
      </Typography>

      {/* List of Health Issues */}
      <List>
        <ListItem>
          <ListItemText primary="• Respiratory issues like asthma and allergies" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Headaches, dizziness, and fatigue" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Heart disease and other cardiovascular problems" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Increased risk of cancer" />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        Understanding the importance of IAQ is the first step toward creating a healthier indoor environment for you and your loved ones.
      </Typography>

      {/* Subheading 2 */}
      <Typography variant="h5" component="h2" gutterBottom>
        Common Indoor Air Pollutants
      </Typography>
      <Typography variant="body1" paragraph>
        Various pollutants can compromise the quality of indoor air. Some of the most common include:
      </Typography>

      {/* List of Pollutants */}
      <List>
        <ListItem>
          <ListItemText primary="• Smoke from tobacco products or cooking" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Mold and mildew" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Volatile Organic Compounds (VOCs) from paints, cleaners, and furnishings" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Pet dander and dust mites" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Chemical pollutants like radon and carbon monoxide" />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        These pollutants can come from both indoor sources and outdoor air that enters the building. Identifying and controlling these sources is crucial for improving IAQ.
      </Typography>

      {/* Subheading 3 */}
      <Typography variant="h5" component="h2" gutterBottom>
        The Impact of Smoke on Indoor Air Quality
      </Typography>
      <Typography variant="body1" paragraph>
        Smoke is one of the most significant contributors to poor indoor air quality. Whether from tobacco, cooking, or other sources, smoke can contain thousands of chemicals, many of which are harmful to human health. In multi-unit buildings like apartments, smoke can easily travel between units through shared ventilation systems, affecting neighbors and leading to health complaints.
      </Typography>

      {/* Subheading 4 */}
      <Typography variant="h5" component="h2" gutterBottom>
        How to Improve Your Indoor Air Quality
      </Typography>
      <Typography variant="body1" paragraph>
        Improving IAQ doesn't have to be complicated. Here are some effective strategies:
      </Typography>

      {/* List of Improvement Strategies */}
      <List>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>1. Control Pollution Sources:</strong> Eliminate or reduce the use of products that emit pollutants. Opt for natural cleaning products and low-VOC paints and furnishings.
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>2. Ensure Adequate Ventilation:</strong> Use exhaust fans in kitchens and bathrooms, and consider installing a whole-house ventilation system to bring in fresh outdoor air.
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>3. Use Air Purifiers:</strong> High-efficiency particulate air (HEPA) filters can remove contaminants from the air, including smoke particles and allergens.
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>4. Regular Maintenance:</strong> Keep your HVAC system well-maintained to ensure it's working efficiently and not circulating pollutants.
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>5. Avoid Indoor Smoking:</strong> Prohibit smoking inside your home to prevent harmful smoke pollutants from affecting indoor air.
              </>
            }
          />
        </ListItem>
      </List>

      {/* Subheading 5 */}
      <Typography variant="h5" component="h2" gutterBottom>
        How the Odorless Tube Can Help
      </Typography>
      <Typography variant="body1" paragraph>
        For those who enjoy smoking indoors but are concerned about IAQ, the <Link href="/products/odorless-tube" underline="hover">Odorless Tube</Link> offers an effective solution. It utilizes advanced activated carbon technology to eliminate smoke odors instantly, ensuring that your indoor air remains fresh and clean. Its portable design and dual-fan suction system make it a convenient option for maintaining good IAQ without compromising on your lifestyle.
      </Typography>

      {/* Image Suggestion */}
      {/* Insert an image of the Odorless Tube */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <motion.div
          whileHover={{ scale: 1.05 }}
          transition={{ type: 'spring', stiffness: 300 }}
          style={{
            position: 'relative',
            borderRadius: '20px',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '600px',
            paddingTop: '56.25%', // 16:9 Aspect Ratio
          }}
        >
          <iframe
            src={`https://www.youtube.com/embed/Jz_BMoTWcIw?autoplay=1&loop=1&playlist=Jz_BMoTWcIw&mute=1&controls=0&modestbranding=1&rel=0&showinfo=0`}
            title="Odorless Tube Video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '80%',
              borderRadius: '20px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
            }}
          ></iframe>
          {/* Optional Overlay */}
          <motion.div
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 0.2 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#000',
              borderRadius: '20px',
              pointerEvents: 'none',
            }}
          />
        </motion.div>
      </Box>

      {/* Subheading 6 */}
      <Typography variant="h5" component="h2" gutterBottom>
        Benefits of Maintaining Good Indoor Air Quality
      </Typography>
      <Typography variant="body1" paragraph>
        Prioritizing IAQ brings numerous benefits:
      </Typography>

      {/* List of Benefits */}
      <List>
        <ListItem>
          <ListItemText primary="• Improved respiratory health" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Enhanced comfort and well-being" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Reduced risk of chronic diseases" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Better sleep quality" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Increased productivity and focus" />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        By taking steps to improve the air you breathe indoors, you're investing in your long-term health and quality of life.
      </Typography>

      {/* Conclusion */}
      <Typography variant="h5" component="h2" gutterBottom>
        Conclusion
      </Typography>
      <Typography variant="body1" paragraph>
        Indoor air quality is a critical but often overlooked aspect of our health. With the majority of our time spent indoors, ensuring the air we breathe is clean and free of pollutants is essential. By understanding the sources of indoor air pollution and taking proactive steps to mitigate them, you can create a healthier environment for yourself and those around you.
      </Typography>
      <Typography variant="body1" paragraph>
        Ready to take control of your indoor air quality? Explore the <Link href="/products/odorless-tube" underline="hover">Odorless Tube</Link> and discover how it can help you maintain a fresh and odor-free home.
      </Typography>

      {/* Call to Action */}
      <Box sx={{ textAlign: 'center', marginTop: 6 }}>
        <Link href="/" underline="none">
          <Box
            sx={{
              backgroundColor: '#ffb300',
              color: '#fff',
              paddingY: 1.5,
              paddingX: 4,
              borderRadius: 2,
              display: 'inline-block',
              '&:hover': {
                backgroundColor: '#FF9422',
              },
            }}
          >
            Buy Now for $99
          </Box>
        </Link>
      </Box>

      {/* Additional Links and References */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          References:
        </Typography>
        <List>
          <ListItem>
            <Link
              href="https://www.epa.gov/indoor-air-quality-iaq/introduction-indoor-air-quality"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              U.S. Environmental Protection Agency - Introduction to Indoor Air Quality
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://www.who.int/news-room/fact-sheets/detail/household-air-pollution-and-health"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              World Health Organization - Household Air Pollution and Health
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://www.cdc.gov/air/indoor-air-quality/index.html"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              Centers for Disease Control and Prevention - Indoor Air Quality
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://www.lung.org/clean-air/at-home/indoor-air-pollutants"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              American Lung Association - Indoor Air Pollutants and Health
            </Link>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
}
