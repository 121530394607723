// Blog.js

import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CardMedia,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { styled } from '@mui/system';
import { SmokeFreeOutlined } from '@mui/icons-material';

// Existing image imports
import ImageAirPurifier from '../assets/images/blog/air-purifier.png';
import ImageAirPurifierRoom from '../assets/images/blog/air-quality-living-room.png';
import ImageSmokeBuddy from '../assets/images/smokebuddy.png';
import ImageActivatedCarbon from '../assets/images/carbon.jpeg';
import ImageSmokeIndoor from '../assets/images/blog/smoke-indoor-apartment.png';
import ImageOdorlessTubeRender from '../assets/images/odorless-tube-render.png';
import ImageGuySmoking from '../assets/images/guy_smoking_room.png';
import ImageOdorRemoval from '../assets/images/product3.png';

// Placeholder images for new blog posts
const placeholderImage = 'https://via.placeholder.com/600x400?text=Blog+Post+Image';

// Styled components for enhanced UI
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 200,
});

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  backgroundColor: '#ffb300',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#FF9422',
  },
}));

// Updated blogPosts array with new blog posts appended
const blogPosts = [
  {
    title: 'Customer Stories: Odorless Tube in Action',
    excerpt:
      'Discover real-life experiences from our customers who have transformed their indoor air quality with the Odorless Tube.',
    link: '/blog/odorless-tube-customer-stories',
    image: ImageGuySmoking,
  },
  {
    title: 'Understanding Activated Carbon',
    excerpt:
      'Dive deep into the science behind activated carbon and how it effectively removes impurities and odors from the air.',
    link: '/blog/activated-carbon',
    image: ImageActivatedCarbon,
  },
  {
    title: 'Odorless Tube: How to Use Guide',
    excerpt:
      'A comprehensive step-by-step guide on setting up and maximizing the efficiency of your Odorless Tube for optimal results.',
    link: '/blog/odorless-tube-guide',
    image: ImageOdorlessTubeRender,
  },
  {
    title: 'The Importance of Indoor Air Quality',
    excerpt:
      'Learn why maintaining good indoor air quality is crucial for your health and well-being, and how to achieve it effectively.',
    link: '/blog/importance-of-indoor-air-quality',
    image: ImageAirPurifierRoom,
  },
  {
    title: 'Tips and Tricks for Smoking Indoors Without Letting Smell Spread',
    excerpt:
      'Explore effective strategies to enjoy smoking indoors while keeping your living spaces fresh and odor-free with the Odorless Tube.',
    link: '/blog/smoking-indoor-tips',
    image: ImageSmokeIndoor,
  },
  {
    title: 'SmokeBuddy vs Odorless Tube: Which is the Best Smoke Odor Eliminator?',
    excerpt:
      'Compare the features of SmokeBuddy and Odorless Tube to discover which device offers superior smoke odor elimination and convenience.',
    link: '/blog/smoke-buddy-vs-odorless-tube',
    image: ImageSmokeBuddy,
  },
  {
    title: 'How to Choose the Right Air Purifier for Your Home',
    excerpt:
      'A comprehensive guide to selecting the best air purifier that fits your needs and ensures optimal air quality in your home.',
    link: '/blog/air-purifier-guide',
    image: ImageAirPurifier,
  },
  {
    title: 'The Science of Odor Removal: Why Some Methods Work Better Than Others',
    excerpt:
      'Understand the scientific principles behind various odor removal methods and why activated carbon stands out in effective odor elimination.',
    link: '/blog/science-odor-removal',
    image: ImageOdorRemoval,
  },
  // New blog posts added below
  {
    title: 'Common Household Odors and How to Eliminate Them',
    excerpt:
      'Every home has its unique set of odors that can sometimes be challenging to manage. From cooking smells to pet odors, understanding the sources and effective solutions is key to maintaining a fresh and welcoming environment.',
    link: '/blog/common-household-odors',
    image: placeholderImage,
  },
  {
    title: 'DIY vs. Professional Odor Removal Solutions',
    excerpt:
      'When it comes to eliminating unwanted odors from your home, you have a variety of options at your disposal. From simple DIY methods to advanced professional solutions, each approach has its own set of advantages and limitations.',
    link: '/blog/diy-vs-professional-odor-removal',
    image: placeholderImage,
  },
  {
    title: 'Top Air Quality Myths Debunked',
    excerpt:
      'Indoor air quality plays a crucial role in our overall health and well-being. However, numerous myths and misconceptions surround this topic, leading many to make uninformed decisions about managing their home environment.',
    link: '/blog/top-air-quality-myths-debunked',
    image: placeholderImage,
  },
  {
    title: 'How to Set Up a Smoke-Free Home for Guests',
    excerpt:
      'Hosting guests in a smoke-free environment can be challenging, especially if you enjoy smoking indoors. However, with the right strategies and tools, you can ensure that your home remains fresh and inviting for all visitors.',
    link: '/blog/set-up-smoke-free-home',
    image: placeholderImage,
  },
  {
    title: 'Activated Carbon vs. Other Odor-Absorbing Materials',
    excerpt:
      'Odor elimination is a common concern for many households, especially those dealing with persistent smells from smoking, pets, or cooking. Various materials are used to absorb and neutralize these odors, including activated carbon, charcoal, and zeolite.',
    link: '/blog/activated-carbon-vs-others',
    image: placeholderImage,
  },
  {
    title: 'Creating a Pet-Friendly, Odor-Free Home',
    excerpt:
      'Pets bring immense joy and companionship, but they can also contribute to household odors. Maintaining a fresh and inviting home environment is essential for both you and your furry friends.',
    link: '/blog/pet-friendly-odor-free-home',
    image: placeholderImage,
  },
  {
    title: 'Environmental Benefits of Using Activated Carbon',
    excerpt:
      'In an era where sustainability and environmental responsibility are paramount, choosing the right products for odor control can make a significant impact. Activated carbon stands out not only for its effectiveness in eliminating odors but also for its eco-friendly attributes.',
    link: '/blog/environmental-benefits-activated-carbon',
    image: placeholderImage,
  },
  {
    title: 'How Poor Indoor Air Quality Affects Health',
    excerpt:
      'Indoor air quality (IAQ) is a critical factor that significantly impacts our health and well-being. With the majority of our time spent indoors, ensuring clean and healthy air is essential.',
    link: '/blog/poor-indoor-air-quality-health-effects',
    image: placeholderImage,
  },
  {
    title: 'Frequently Asked Questions About the Odorless Tube',
    excerpt:
      'The Odorless Tube by SmokeAwaySolutions has garnered attention for its effectiveness in eliminating smoke odors and improving indoor air quality. To help you make an informed decision, we\'ve compiled a list of frequently asked questions.',
    link: '/blog/odorless-tube-faq',
    image: placeholderImage,
  },
  {
    title: 'The Ultimate Guide to Reducing Smoke Odor in Small Apartments',
    excerpt:
      'Living in a small apartment presents unique challenges when it comes to managing smoke odors. Limited space can make it difficult to disperse smoke effectively, leading to lingering smells that affect your living environment.',
    link: '/blog/reducing-smoke-odor-small-apartments',
    image: placeholderImage,
  },
  // Add more blog posts as needed
];

function Blog() {
  const theme = useTheme();
  const [visiblePosts, setVisiblePosts] = useState(10); // Initial number of posts to display

  // Function to handle "Load More" button click
  const handleLoadMore = () => {
    setVisiblePosts((prev) => prev + 10);
  };

  // Determine if there are more posts to show
  const hasMore = visiblePosts < blogPosts.length;

  return (
    <Container maxWidth="lg" sx={{ paddingY: 8 }}>
      {/* Blog Introduction */}
      <Box sx={{ textAlign: 'center', marginBottom: 6 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Welcome to Our Blog
        </Typography>
        <Typography variant="h6" component="p" color="textSecondary">
          Stay updated with the latest insights, tips, and trends on air quality, odor elimination, and sustainable living. Our blog posts are crafted to provide valuable information that helps you make informed decisions for a healthier and fresher environment.
        </Typography>
      </Box>

      {/* Blog Posts Grid */}
      <Grid container spacing={4}>
        {blogPosts.slice(0, visiblePosts).map((post, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              {/* Blog Post Image */}
              <StyledCardMedia
                component="img"
                image={post.image}
                alt={post.title}
                loading="lazy" // Improves performance by lazy loading images
              />

              {/* Blog Post Content */}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {post.excerpt}
                </Typography>
              </CardContent>

              {/* Blog Post Actions */}
              <CardActions>
                <StyledButton
                  size="small"
                  component={RouterLink}
                  to={post.link}
                  aria-label={`Read more about ${post.title}`}
                >
                  Read More
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      {/* Load More Button */}
      {hasMore && (
        <Box sx={{ textAlign: 'center', marginTop: 6 }}>
          <StyledButton
            variant="contained"
            onClick={handleLoadMore}
            aria-label="Load more blog posts"
          >
            Load More
          </StyledButton>
        </Box>
      )}

      {/* Optional: Message when no more posts are available */}
      {!hasMore && (
        <Box sx={{ textAlign: 'center', marginTop: 6 }}>
          <Typography variant="body1" color="textSecondary">
            You've reached the end of the blog posts.
          </Typography>
        </Box>
      )}

      {/* Call to Action for New Blog Posts */}
      <Box sx={{ textAlign: 'center', marginTop: 8 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Looking for More Insights?
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We regularly update our blog with articles on topics that matter to you. From the science behind air purification to practical tips for maintaining a fresh living space, our blog is your go-to resource for all things related to air quality and odor elimination.
        </Typography>

      </Box>
    </Container>
  );
}

export default Blog;
