// src/App.js
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4'; // Import react-ga4
import { keyframes } from '@mui/system';
import { addEmail } from './utils/firebaseConfig';
import { loadStripe } from '@stripe/stripe-js';
import { BlogPostActivatedCarbon } from './pages/blog/BlogActivatedCarbon';
import { BlogPostIndoorAirQuality } from './pages/blog/BlogPostIndoorAirQuality';
import { BlogPostSmokingIndoorTips } from './pages/blog/BlogPostSmokingIndoorTips';
import { BlogPostAirPurifierGuide } from './pages/blog/BlogPostAirPurifierGuide';
import { BlogPostScienceOfOdorRemoval } from './pages/blog/BlogPostScienceOfOdorRemoval';
import { BlogPostStepByStepGuide } from './pages/blog/BlogPostStepByStepGuide';
import { BlogPostCustomerStories } from './pages/blog/BlogPostCustomerStories';
import BlogPostSmokeBuddyVsOdorlessTube from './pages/blog/BlogPostSmokeBuddyVsOdorlessTube';

import BlogPostActivatedCarbonComparison from './pages/blog/BlogPostActivatedCarbonComparison';
import BlogPostCommonHouseholdOdors from './pages/blog/BlogPostCommonHouseholdOdors';
import BlogPostDIYvsProfessionalOdorRemoval from './pages/blog/BlogPostDIYvsProfessionalOdorRemoval';
import BlogPostEnvironmentalBenefitsActivatedCarbon from './pages/blog/BlogPostEnvironmentalBenefitsActivatedCarbon';
import { BlogPostOdorlessTubeFAQ } from './pages/blog/BlogPostOdorlessTubeFAQ';
import BlogPostPetFriendlyOdorFreeHome from './pages/blog/BlogPostPetFriendlyOdorFreeHome';
import BlogPostReducingSmokeOdorSmallApartments from './pages/blog/BlogPostReducingSmokeOdorSmallApartments';
import BlogPostSetUpSmokeFreeHome from './pages/blog/BlogPostSetUpSmokeFreeHome';
import BlogPostTopAirQualityMythsDebunked from './pages/blog/BlogPostTopAirQualityMythsDebunked';

import Blog from './pages/Blog'; // Add this line

import {
  AppBar,
  TextField,
  Toolbar,
  Typography,
  Button,
  Container,
  Chip,
  Grid,
  Divider,
  Card,
  CardMedia,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ThemeProvider,
  createTheme,
  Box,
  IconButton,
  Tooltip,
  useScrollTrigger,
  Fab,
  Zoom,
  Link as MuiLink,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  ListItemIcon,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import AirIcon from '@mui/icons-material/Air';
import HomeIcon from '@mui/icons-material/Home';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { CheckCircle } from '@mui/icons-material'; // Import CheckCircle icon
import { motion } from 'framer-motion';
import Slider from 'react-slick'; // Import react-slick for the carousel
import 'slick-carousel/slick/slick.css'; // Import slick-carousel CSS
import 'slick-carousel/slick/slick-theme.css'; // Import slick-carousel theme CSS
import './App.css'; // Optional: For any additional global styles
import '@fontsource/poppins'; // Import Poppins font
import logo from './assets/images/logo.png';
import heroBackground from './assets/images/hero-background.png';
import KickstarterBanner from './components/KickStarterBanner';

import './assets/fonts/Frankfurter.ttf';
// Import Product Showcase Images
import product1 from './assets/images/product1.png';
import product2 from './assets/images/product2.png';
import product3 from './assets/images/product3.png';

// Import Pricing Images
import price1 from './assets/images/price1.jpg';
import price2 from './assets/images/price2.jpg';

import carbonImg from './assets/images/carbon.jpeg';
// Removed the local howItWorksVideo import
import heroVideo from './assets/videos/hero-video.mp4'; // New Hero Video
import productTech1 from './assets/videos/product-tech-1.mp4';
import productTech2 from './assets/videos/product-tech-2.mp4';
import productTech3 from './assets/videos/product-tech-3.mp4';  

// Import Temporary Kickstarter Logo
import kickstarterLogo from './assets/images/tq0sfld-kickstarter-logo-green.avif'; // Temporary WebP file

// Import Pages
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ContactUs from './pages/ContactUs';

// Mock imports for Product Images
import productImage1 from './assets/images/product-2.jpg';
import productImage2 from './assets/images/product-4.jpg';
import productImage3 from './assets/images/product-1.jpg';
import productImage4 from './assets/images/product-3.jpg';





import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('your-public-key-here');
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

// Define the products
const products = [
  {
    id: 1,
    name: 'Odorless Tube Standard',
    description: [
      { icon: <CheckCircle color="primary" />, text: 'Length: Up to 1 meter' },
      { icon: <CheckCircle color="primary" />, text: 'Diameter: 10 cm' },
      { icon: <CheckCircle color="primary" />, text: 'Fans: 1 efficient fan' },
      { icon: <CheckCircle color="primary" />, text: 'Activated Carbon Filters: Reliable activated carbon filters for effective odor removal' },
      { icon: <CheckCircle color="primary" />, text: 'Charging: Powered via one USB-A cable' },
    ],
    price: '$99',
    priceId: 'price_abcdef1234567890', // Replace with your actual price ID from Stripe
    image: price1,
    stripePaymentLink: 'https://buy.stripe.com/4gwaIv69z0cgbpC7su',
  },
  {
    id: 2,
    name: 'Odorless Tube Pro',
    description: [
      { icon: <CheckCircle color="primary" />, text: 'Length: Up to 2 meters (adjustable)' },
      { icon: <CheckCircle color="primary" />, text: 'Diameter: 10 cm' },
      { icon: <CheckCircle color="primary" />, text: 'Fans: 2 high-efficiency fans for increased suction power' },
      { icon: <CheckCircle color="primary" />, text: 'Activated Carbon Filters: Enhanced activated carbon for superior odor elimination' },
      { icon: <CheckCircle color="primary" />, text: 'Charging: Powered via one USB-A cable' },
    ],
    price: '$159',
    priceId: 'price_1234567890abcdef', // Replace with your actual price ID from Stripe
    image: price2,
    stripePaymentLink: 'https://buy.stripe.com/7sI8AnfK9f7a65ifZ1',
  }
];

const scroll = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
`;


// Create a modern theme while retaining the original color essence
const theme = createTheme({
  palette: {
    primary: {
      main: '#43a047', // Rich green
    },
    secondary: {
      main: '#ffb300', // Warm amber
    },
    background: {
      default: '#f6f2ec',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
  },
  typography: {
    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
    },
  },
});

// Initialize Google Analytics
ReactGA.initialize('G-CJN1K4H9VX'); // Replace with your Measurement ID

// Custom hook to track page views
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

// Function to log custom events
const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

// Scroll to top functionality
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 32, right: 32 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

// Countdown Timer Component
const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date('2024-12-31') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <Box key={interval} sx={{ display: 'inline-block', marginX: 1 }}>
      <Typography variant="h6" component="span" color="secondary">
      {timeLeft[interval]}{' '}
      </Typography>
      <Typography variant="body2" component="span">
        {interval}
      </Typography>
    </Box>
  ));

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2" color="inherit">
        Offer ends in: {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </Typography>
    </Box>
  );
};

function AppContent(props) {
  usePageViews(); // Track page views
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  // State for the product selection dialog
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  // State variables for email input
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  // Function to handle email submission
  const handleEmailSubmit = async (event) => {
    const response = await addEmail({ email });
    console.log(response)

    event.preventDefault();
    // Log the event for analytics
    logEvent('User', 'Submit Email', email);
    // Implement your email submission logic here (e.g., send to an API endpoint)
    setEmailSubmitted(true);
    // Optionally, clear the email input
    setEmail('');
  };

  // Updated handleBuyNow with event tracking
  const handleBuyNow = () => {
    logEvent('User', 'Click Buy Now Button', 'Buy Now Button');
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Updated handleSelectProduct with event tracking
  const handleSelectProduct = (product) => {
    logEvent('User', 'Select Product', `Selected ${product.name}`);

    // Assume each product has a `stripePaymentLink` property
    const stripePaymentLink = product.stripePaymentLink;

    if (stripePaymentLink) {
      // Log navigation to external Stripe link
      logEvent('Navigation', 'Redirect to Stripe', stripePaymentLink);
      // Open the Stripe payment link in a new tab
      window.open(stripePaymentLink, '_blank');
    } else {
      console.error('Stripe payment link not available for this product.');
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  };

  const trigger = useScrollTrigger({
    threshold: 100, // Adjust as needed
    disableHysteresis: true,
  });

  const [shrink, setShrink] = React.useState(false);

  React.useEffect(() => {
    if (trigger) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  }, [trigger]);

  const navItems = [
    { label: 'Home', path: '/#home' },
    { label: 'How It Works', path: '/#how-it-works' },
    { label: 'Contact Us', path: '/contact-us' },
    { label: 'Blog', path: '/blog' },
    { label: 'Buy Now', path: 'buy-now' }, // Updated path for "Buy Now"
  ];
  const handleNavItemClick = (path) => {
    const currentPath = window.location.pathname;
  
    // Check if the path is a section on the home page (starts with '/#')
    if (path.startsWith('/#')) {
      const id = path.replace('/#', '');
      
      // If on a different path, navigate to '/' first
      if (currentPath !== '/') {
        navigate('/');
        setTimeout(() => {
          // Wait for navigation to '/' before scrolling
          const anchor = document.getElementById(id);
          if (anchor) {
            anchor.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
          logEvent('Navigation', 'Scroll to Section', id);
        }, 100); // Adjust the delay if necessary
      }
      
      else {
        // If already on '/', just scroll
        const anchor = document.getElementById(id);
        if (anchor) {
          anchor.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        logEvent('Navigation', 'Scroll to Section', id);
      }
    } else if (path === 'buy-now') {
      handleBuyNow(); // Handle "Buy Now" click event
      if(currentPath!="/"){
        navigate("/")

      }
    } else {
      navigate(path); // Handle standard navigation for '/blog' and other paths
      logEvent('Navigation', 'Navigate to Page', path);
    }
  
    if (isMobile) {
      setMobileOpen(false);
    }
  };
  
  

  return (
    <div className="App">
      {/* Header */}
      <AppBar 
        sx={{
    backgroundColor: 'rgba(67, 160, 71, 0.85)', // Adjust the RGBA values as needed
  }}
      position="sticky" color="primary" elevation={shrink ? 4 : 0}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <motion.img
              src={logo}
              alt="SmokeAwaySolutions Logo"
              style={{
                height: shrink ? '35px' : '45px',
                marginRight: '10px',
                borderRadius: '40px',
                transition: 'height 0.3s',
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // Add shadow for depth
              }}
              onClick={() => handleNavItemClick('/')}
              loading="lazy"
            />
            <motion.div
              animate={{ fontSize: shrink ? '1.2rem' : '1.5rem' }}
              transition={{ duration: 0.3 }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ cursor: 'pointer', color: 'white', fontWeight: 'bold' }}
                onClick={() => handleNavItemClick('/')}
              >
                SmokeAwaySolutions
              </Typography>
            </motion.div>
          </Box>

          {/* Navigation Links - Aligned to the Right */}
          {!isMobile ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {navItems.map((item, index) => (
                <Button
                  key={index}
                  color="inherit"
                  onClick={() => handleNavItemClick(item.path)}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          ) : (
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleDrawerToggle}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Toolbar id="back-to-top-anchor" />
      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'primary.main',
            color: 'white',
          },
        }}
      >
        <Box sx={{ width: 250, padding: 2 }}>
          {/* Header inside Drawer */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: 40, marginRight: 8, borderRadius: 20 }}
              loading="lazy"
            />
            <Typography
              variant="p"
              sx={{ fontWeight: 'bold', flexGrow: 1 }}
            >
              SmokeAwaySolutions
            </Typography>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ backgroundColor: 'white', marginBottom: 2 }} />
          <List>
            {navItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleNavItemClick(item.path)}
                sx={{
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  paddingY: 1,
                }}
              >
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      sx={{ color: 'white', fontWeight: 'bold' }}
                    >
                      {item.label}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>


      {/* Main Sections */}
      <Routes>
        <Route
          path="/"
          element={
            <>
      {/* Hero Section */}
      <motion.div
        id="home"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Box
          id="home"
          sx={{
            backgroundImage: `linear-gradient(135deg, rgba(67, 160, 71, 0.8), rgba(255, 179, 0, 0.8)), url(${heroBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
            marginTop: { xs: '-160px',sm:'-175px', md: '-164px', xl:'-194px' },
            paddingY: { xs: 6, md: 0 },
          }}
        >
            <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '100vh', md: '100vh' }, // Full viewport height on all devices
        overflow: 'hidden',
      }}
    >
      {/* Video */}
      <motion.video
        src={heroVideo}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        autoPlay
        loop
        muted
        playsInline
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      />

      {/* Overlay to darken video for better text readability */}
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      />

      {/* Text content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          color: '#fff',
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{
              textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
              fontSize: { xs: '2rem', md: '3rem' },
              lineHeight: 1.2,
            }}
          >
            Enjoy Smoking Indoors Without the Odor or Complaints
          </Typography>
          <Typography
            variant="h6"
            paragraph
            sx={{
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
              fontSize: { xs: '1.1rem', md: '1.5rem' },
            }}
          >
            Our portable activated carbon device eliminates smoke odors instantly, keeping your space fresh and your neighbors happy.
          </Typography>

          {/* Buy Now Button */}
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleBuyNow}
            sx={{
              marginTop: 2,
              paddingX: 4,
              paddingY: 1.5,
              fontSize: { xs: '1rem', md: '1.2rem' },
            }}
          >
            Buy Now for $99
          </Button>
          <Typography
            variant="body2"
            sx={{
              marginTop: 1,
              fontSize: { xs: '0.9rem', md: '1rem' },
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
            }}
          >
            Limited time offer with free shipping!
          </Typography>

          {/* Countdown Timer */}
          <CountdownTimer />
        </Container>
      </Box>
    </Box>
                </Box>

              <KickstarterBanner/>


              </motion.div>




              {/* How It Works Section */}
              <motion.div
                id="how-it-works"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container maxWidth="md" sx={{ py: 8 }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: 'bold' }}
                  >
                    How It Works
                  </Typography>

                  <Box
                    sx={{
                      width: { xs: '90%', sm: '70%' }, // 90% on small screens, 70% on medium and up
                      margin: '0 auto', // Centers the box horizontally
                      mt: 4, // Top margin
                    }}
                  >
                    <Typography variant="body1" color="text.secondary" paragraph align="center">
                      Our device instantly eliminates smoke odors using a powerful dual-fan system and activated carbon filters:
                    </Typography>

                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <ListItemIcon sx={{ position: 'relative', top: '14px'}}>
                          <AirIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1" color="text.secondary">
                              <strong>Dual-Fan Suction System:</strong> Draws smoke efficiently through the device.
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <ListItemIcon sx={{ position: 'relative', top: '14px'}}>
                          <FilterVintageIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1" color="text.secondary">
                              <strong>Activated Carbon Filters:</strong> Absorbs and neutralizes odors at the molecular level.
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <ListItemIcon sx={{ position: 'relative', top: '14px'}}>
                          <HomeIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1" color="text.secondary">
                              <strong>Clean Air Output:</strong> Pushes odorless air out through your window, preventing lingering smoke.
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <ListItemIcon sx={{ position: 'relative', top: '14px'}}>
                          <MobileFriendlyIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1" color="text.secondary">
                              <strong>Portable Design:</strong> Use it anywhere for a discreet indoor smoking experience without affecting others.
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>

                  {/* YouTube Video Embed */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 300 }}
                      style={{
                        position: 'relative',
                        borderRadius: '20px',
                        overflow: 'hidden',
                        width: '100%',
                        maxWidth: '600px', // Ensure the container has a max width
                        paddingTop: '56.25%', // 16:9 Aspect Ratio
                      }}
                    >
                      <iframe
                        src={`https://www.youtube.com/embed/Jz_BMoTWcIw?autoplay=1&loop=1&playlist=Jz_BMoTWcIw&mute=1&controls=0&modestbranding=1&rel=0&showinfo=0`}
                        title="How It Works Video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '20px',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                        }}
                      ></iframe>
                      {/* Optional Overlay */}
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 0.2 }}
                        transition={{ duration: 0.3 }}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#000',
                          borderRadius: '20px',
                          pointerEvents: 'none', // Allows interactions with the video
                        }}
                      />
                    </motion.div>
                  </Box>
                </Container>
              </motion.div>

              {/* Money-Back Guarantee Section */}
              <motion.div
                id="guarantee"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container
                  maxWidth="md"
                  sx={{
                    paddingY: 8,
                    backgroundColor: '#ffb300',
                    borderRadius: 2,
                    marginTop: 4,
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  <Typography variant="h4" component="h2" gutterBottom>
                    30-Day Money-Back Guarantee
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We are confident that SmokeAwaySolutions will exceed your expectations. If you're not completely satisfied within 30 days of purchase, we'll refund your money—no questions asked.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleBuyNow} // Event tracking on click
                    sx={{
                      marginTop: 2,
                      paddingX: 4,
                      paddingY: 1.5,
                      fontSize: '1.1rem',
                      backgroundColor: 'white',
                      color: '#ffb300',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    Try It Risk-Free
                  </Button>
                </Container>
              </motion.div>




              {/* Product Showcase Section */}
              <motion.div
                id="product"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container maxWidth="lg" sx={{ paddingY: 8 }}>
                  <Typography variant="h4" component="h2" align="center" gutterBottom>
                    Product Technology
                  </Typography>
                  <Grid container spacing={4} sx={{ marginTop: 4 }}>
                    {[
                      {
                        title: 'Dual-Fan Suction System',
                        description:
                          'Efficiently removes smoke by drawing it in and expelling fresh air, ensuring a clean and odor-free environment.',
                        icon: <AirIcon fontSize="large" color="primary" />,
                        video: productTech2,
                      },
                      {
                        title: 'Layers of Activated Carbon Fiber',
                        description:
                          'Multiple layers of activated carbon fiber provide superior odor absorption, effectively neutralizing unwanted smells.',
                        icon: <FilterVintageIcon fontSize="large" color="primary" />,
                        video: productTech1,
                      },
                      {
                        title: 'Activated Carbon for Odor Removal',
                        description:
                          'Premium activated carbon eliminates smoke odors at the molecular level, maintaining a pleasant indoor atmosphere.',
                        icon: <HomeIcon fontSize="large" color="primary" />,
                        video: productTech3,
                      },
                    ].map((feature, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          transition={{ type: 'spring', stiffness: 300 }}
                          style={{ height: '100%' }} // Ensure the motion.div takes full height
                        >
                          <Card
                            elevation={3}
                            sx={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              backgroundColor: '#ffffff',
                              borderRadius: 2,
                              textAlign: 'center',
                              padding: 2,
                              cursor: 'pointer',
                              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                              transition: 'transform 0.3s, box-shadow 0.3s',
                              '&:hover': {
                                boxShadow: '0 6px 20px rgba(0,0,0,0.12)',
                              },
                            }}
                          >
                            <Box sx={{ textAlign: 'center', padding: 2, flexGrow: 1 }}>
                              {React.cloneElement(feature.icon, {
                                fontSize: 'large',
                                color: 'primary',
                              })}
                              <Typography
                                variant="h6"
                                gutterBottom
                                color="primary"
                                sx={{ marginTop: 2 }}
                              >
                                {feature.title}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {feature.description}
                              </Typography>
                            </Box>
                            {/* Product Image */}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 2,
                                paddingBottom: 2, // Added padding to ensure uniform spacing
                              }}
                            >
                          
  
                            <motion.video
                              src={feature.video}
                              alt="SmokeAwaySolutions in action"
                              style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '20px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                              }}
                              autoPlay
                              loop
                              muted
                              playsInline
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1 }}
                              loading="lazy"
                            >
                              Your browser does not support the video tag.
                            </motion.video>

{/*         
                              <CardMedia
                                component="img"
                                image={feature.image}
                                alt={feature.title}
                                sx={{
                                  width: '100%',
                                  height: 200, // Fixed height for uniformity
                                  objectFit: 'cover', // Ensures image covers the area without distortion
                                  borderRadius: '8px',
                                  border: '2px solid #ffb300',
                                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }}
                                loading="lazy"
                              /> */}
                            </Box>
                          </Card>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </motion.div>

              {/* Features & Benefits Section */}
              <motion.div
                id="features"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container maxWidth="lg" sx={{ paddingY: 8 }}>
                  <Typography variant="h4" component="h2" align="center" gutterBottom>
                    Features & Benefits
                  </Typography>
                  <Grid container spacing={4} sx={{ marginTop: 4 }}>
                    {[
                      {
                        title: 'Advanced Odor Elimination',
                        description:
                          'Utilizes activated carbon—the scientifically proven method to remove smoke odors effectively.',
                        icon: <FilterVintageIcon fontSize="large" color="primary" />,
                      },
                      {
                        title: 'Dual-Fan Suction System',
                        description:
                          'Sucks air through a tube filled with activated carbon filters, removing odor and pushing clean air outside through your window.',
                        icon: <AirIcon fontSize="large" color="primary" />,
                      },
                      {
                        title: 'Portable and Unique',
                        description:
                          'Compact, mobile design allows you to use it anywhere, anytime. There’s nothing else like it!',
                        icon: <MobileFriendlyIcon fontSize="large" color="primary" />,
                      },
                      {
                        title: 'USB-A Powered Fans',
                        description:
                          'Each fan is powered by a USB-A cord. Use two cords for maximum efficiency, or one for single-fan operation.',
                        icon: <AirIcon fontSize="large" color="primary" />,
                      },
                    ].map((feature, index) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          transition={{ type: 'spring', stiffness: 300 }}
                          style={{ height: '100%' }} // Ensure the motion.div takes full height
                        >
                          <Card
                            elevation={3}
                            sx={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              backgroundColor: '#ffffff',
                              borderRadius: 2,
                              textAlign: 'center',
                              padding: 2,
                              cursor: 'pointer',
                              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                              transition: 'transform 0.3s, box-shadow 0.3s',
                              '&:hover': {
                                boxShadow: '0 6px 20px rgba(0,0,0,0.12)',
                              },
                            }}
                          >
                            <Box sx={{ textAlign: 'center', padding: 2, flexGrow: 1 }}>
                              {React.cloneElement(feature.icon, {
                                fontSize: 'large',
                                color: 'primary',
                              })}
                              <Typography
                                variant="h6"
                                gutterBottom
                                color="primary"
                                sx={{ marginTop: 2 }}
                              >
                                {feature.title}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {feature.description}
                              </Typography>
                            </Box>
                          </Card>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </motion.div>

              {/* The Science Behind Activated Carbon */}
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container
                  maxWidth="md"
                  sx={{
                    paddingY: 8,
                    backgroundColor: '#e8f5e9',
                    borderRadius: 2,
                    marginTop: 4,
                  }}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    align="center"
                    gutterBottom
                    color="primary"
                  >
                    The Science Behind Activated Carbon
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    Activated carbon is processed to have small, low-volume pores that increase the
                    surface area available for adsorption. This makes it exceptionally effective at
                    trapping odor-causing molecules from smoke. It's one of the only materials capable of
                    removing smoke odors efficiently, making it ideal for our device.
                  </Typography>

                  {/* Image below the text */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 4,
                    }}
                  >
                    <img
                      src={carbonImg}
                      alt="Activated Carbon Illustration"
                      style={{
                        width: '50%',
                        height: 'auto',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      }}
                      loading="lazy"
                    />
                  </Box>

                  {/* Additional sources for further reading */}
                  <Typography variant="h6" component="h3" color="primary" sx={{ marginTop: 4 }}>
                    Learn More About Activated Carbon:
                  </Typography>
                  <Typography variant="body1" color="text.secondary" component="ul">
                  <li>
                      <a href="https://www.jstor.org/stable/24585446" target="_blank" rel="noopener noreferrer">
                        JSTOR - Efficient Control of Odors via Activated Carbon Technology
                      </a>
                    </li>
                    <li>
                      <a href="https://www.mdpi.com/2073-4441/16/2/280" target="_blank" rel="noopener noreferrer">
                        MDPI - Progress made in Research on Odor Removal 
                      </a>
                    </li>
                    <li>
                      <a href="https://phys.org/news/2023-04-technology-reduction-daily-odors.html" target="_blank" rel="noopener noreferrer">
                        Phys.org - Technology Reduction Daily Odor (NCBI)
                      </a>
                    </li>
                    <li>
                      <a href="https://iwaponline.com/ws/article-abstract/16/4/1017/28049/Odor-removal-by-powdered-activated-carbon-PAC-in?redirectedFrom=fulltext" target="_blank" rel="noopener noreferrer">
                        IWA Publishing - Odor removal in drinking water
                      </a>
                    </li>
                    <li>
                      <a href="https://en.wikipedia.org/wiki/Activated_carbon" target="_blank" rel="noopener noreferrer">
                        Wikipedia: Activated Carbon
                      </a>
                    </li>
                  </Typography>
                </Container>
              </motion.div>





{/* Product Images Section */}
<motion.div
  id="product-images"
  initial={{ opacity: 0, y: 50 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ duration: 1 }}
  viewport={{ once: true }}
>
  <Container maxWidth="lg" sx={{ paddingY: 8 }}>
    <Typography variant="h4" component="h2" align="center" gutterBottom>
    Key Features of the Odorless Tube
    </Typography>
    <Grid container spacing={4} sx={{ marginTop: 4 }}>
      {[
        {
          image: productImage1,
          subtext: 'Compact and Portable Design',
        },
        {
          image: productImage2,
          subtext: 'High-Efficiency Activated Carbon Filters',
        },
        {
          image: productImage3,
          subtext: 'Dual-Fan Suction System',
        },
        {
          image: productImage4,
          subtext: 'Sleek and Modern Aesthetic',
        },
      ].map((product, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <Card
              elevation={3}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                },
              }}
            >
              {/* Product Image */}
              <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={`Product Image ${index + 1}`}
                sx={{
                  objectFit: 'cover',
                }}
                loading="lazy"
              />

              {/* Subtext */}
              <Box sx={{ padding: 2, textAlign: 'center', flexGrow: 1 }}>
                <Typography variant="body1" color="text.secondary">
                  {product.subtext}
                </Typography>
              </Box>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  </Container>
</motion.div>





              {/* Testimonials */}
              <motion.div
                id="testimonials"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container maxWidth="md" sx={{ paddingY: 8 }}>
                  <Typography variant="h4" component="h2" align="center" gutterBottom>
                    What Our Customers Say
                  </Typography>
                  <Grid container spacing={4} sx={{ marginTop: 4 }}>
                    {[
                      {
                        quote:
                          'This device has transformed my indoor experience. No more lingering odors, and my neighbors haven’t complained once!',
                        author: 'Emily R.',
                      },
                      {
                        quote:
                          'Finally, I can enjoy a smoke indoors without worrying about the smell. The activated carbon filters work like magic.',
                        author: 'Michael S.',
                      },
                    ].map((testimonial, index) => (
                      <Grid item xs={12} key={index}>
                        <Card
                          elevation={3}
                          sx={{
                            padding: 3,
                            backgroundColor: '#f1f8e9',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            borderRadius: 2,
                            position: 'relative',
                            '&:before': {
                              content: '"“"',
                              fontSize: '5rem',
                              color: '#43a047',
                              position: 'absolute',
                              top: '-20px',
                              left: '20px',
                              opacity: 0.1,
                            },
                            '&:hover': {
                              boxShadow: '0 6px 20px rgba(0,0,0,0.12)',
                              transform: 'scale(1.02)',
                            },
                          }}
                        >
                          <Typography variant="body1" color="text.secondary" gutterBottom>
                            {testimonial.quote}
                          </Typography>
                          <Typography variant="subtitle1" align="right" color="primary">
                            - {testimonial.author}
                          </Typography>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </motion.div>

              {/* FAQ Section */}
              <motion.div
                id="faq"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Container maxWidth="md" sx={{ paddingY: 8 }}>
                  <Typography variant="h4" component="h2" align="center" gutterBottom>
                    Frequently Asked Questions
                  </Typography>
                  <Box sx={{ marginTop: 4 }}>
                    {[
                      {
                        question: 'How are the fans powered?',
                        answer: 'Both the Odorless Tube Standard and Odorless Tube Pro are powered by a single USB-A cord. This single cord powers both fans efficiently, and it can be plugged into any USB port or power bank.',
                      },

                      {
                        question: 'Is the device mobile?',
                        answer:
                          'Yes, the device is compact and portable, allowing you to use it anywhere, anytime. Its unique design ensures convenience and discretion.',
                      },
                      {
                        question: 'Does it really remove cigarette smoke?',
                        answer:
                          'Absolutely! Our device uses activated carbon technology, which is scientifically proven to effectively remove smoke odors at the molecular level.',
                      },
                      {
                        question: 'How often do I need to replace the filters?',
                        answer:
                          'It depends on usage, but we recommend replacing the activated carbon filters every 2-3 months for optimal performance.',
                      },
                      {
                        question: 'Can it be used for other types of smoke?',
                        answer:
                          'Yes, the device is effective at removing odors from various types of smoke, including tobacco, cannabis, and cooking fumes.',
                      },
                    ].map((faq, index) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography variant="h6">{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body1" color="text.secondary">
                            {faq.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Container>
              </motion.div>

              {/* Buy Now Section */}
              <motion.div
                id="buy-now"
                initial={{ scale: 0.9 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
              >
                <Container
                  maxWidth="md"
                  sx={{
                    paddingY: 8,
                    textAlign: 'center',
                    backgroundColor: '#43a047',
                    borderRadius: 2,
                    marginTop: 8,
                    color: 'white',
                  }}
                >
                  <Typography variant="h4" component="h2" gutterBottom>
                    Ready to Eliminate Smoke Odors?
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Join thousands of satisfied customers and enjoy a fresh, odor-free environment today.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleBuyNow} // Event tracking on click
                    sx={{
                      marginTop: 2,
                      paddingX: 4,
                      paddingY: 1.5,
                      fontSize: '1.1rem',
                      backgroundColor: 'white',
                      color: '#43a047',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    Buy Now
                  </Button>
                  {/* Kickstarter Banner */}
                  <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h6" color="white" sx={{fontWeight: 'bold'}}  >
                      🎉 Coming Soon to Kickstarter!
                    </Typography>
                    <Typography variant="body2" color="white" paragraph>
                      Support our development phase by purchasing now at the reduced price. Early buyers help us make the Odorless Tube even better!
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => window.open('https://www.kickstarter.com/profile/smokeawaysolutions', '_blank')}
                      sx={{
                        marginTop: 1,
                        borderColor: 'white',
                        color: 'white',
                        '&:hover': {
                          borderColor: 'white',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                      }}
                    >
                      Learn More
                    </Button>
                  </Box>
                </Container>
              </motion.div>












              {/* Product Selection Dialog */}
              <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
      scroll="paper" // Enable scrolling within the dialog content
      TransitionComponent={Slide}
      aria-labelledby="product-selection-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: 4,
          // Removed overflow: 'hidden' to allow scrolling
          boxShadow: 24,
        },
      }}
    >
      <DialogTitle
        id="product-selection-dialog-title"
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1.5rem',
        }}
      >
        Choose Your Odorless Tube
      </DialogTitle>

      <DialogContent dividers>
        {/* Shipping and Kickstarter Support Message */}
        <Box sx={{ marginBottom: 4, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            🚚 <strong>Shipping Starts December 12!</strong> Support us in developing a more durable and beautiful product.
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            🌟 <strong>Buy Now at a Reduced Price</strong> to help us enhance the <strong>Odorless Tube</strong>. Other methods are great, but our advanced device stands out as the go-to choice for indoor smokers.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            🎯 <strong>Soon to be on Kickstarter!</strong> Your early support makes a significant difference.
          </Typography>
        </Box>

        {/* Product Cards */}
        <Grid container spacing={4}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} key={product.id}>
              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                    },
                  }}
                >
                  {/* Product Image */}
                  <CardMedia
                    component="img"
                    height="200"
                    image={product.image}
                    alt={product.name}
                    sx={{
                      objectFit: 'cover',
                    }}
                    loading="lazy"
                  />

                  {/* Product Details */}
                  <Box sx={{ padding: 3, flexGrow: 1 }}>
                    {/* Product Name with Optional Badge */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="h5" component="div" sx={{ fontWeight: 600, color: 'primary.main' }}>
                        {product.name}
                      </Typography>
                      {product.isBestSeller && (
                        <Chip
                          label="Best Seller"
                          color="secondary"
                          size="small"
                          sx={{ fontWeight: 'bold' }}
                        />
                      )}
                    </Box>

                    {/* Product Features */}
                    <List sx={{ paddingY: 1 }}>
                      {product.description.map((item, index) => (
                        <ListItem key={index} disableGutters sx={{ paddingY: 0.5 }}>
                          <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" color="text.secondary">
                                {item.text}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>

                    {/* Product Price */}
                    <Typography variant="h6" color="secondary" sx={{ marginTop: 2 }}>
                      {product.price}
                    </Typography>
                  </Box>

                  {/* Buy Now Button */}
                  <Box sx={{ padding: 3 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleSelectProduct(product)}
                      sx={{
                        paddingY: 1.5,
                        fontSize: '1rem',
                        fontWeight: 600,
                        textTransform: 'none',
                        borderRadius: 1,
                        transition: 'background-color 0.3s',
                        '&:hover': {
                          backgroundColor: 'primary.dark',
                        },
                      }}
                    >
                      Buy Now
                    </Button>
                  </Box>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', padding: 3 }}>
        <Button
          onClick={handleCloseDialog}
          color="secondary"
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontWeight: 600,
            paddingX: 4,
            paddingY: 1,
            borderRadius: 1,
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
            </>
          }
        />







        {/* Privacy Policy Route */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* Terms of Service Route */}
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* Contact Us Route */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} /> 
        <Route path="/blog/activated-carbon" element={<BlogPostActivatedCarbon />} /> 
        <Route path="/blog/importance-of-indoor-air-quality" element={<BlogPostIndoorAirQuality />} /> 
        <Route path="/blog/smoking-indoor-tips" element={<BlogPostSmokingIndoorTips />} /> 
        <Route path="/blog/air-purifier-guide" element={<BlogPostAirPurifierGuide />} /> 
        <Route path="/blog/science-odor-removal" element={<BlogPostScienceOfOdorRemoval />} />
        <Route path="/blog/odorless-tube-guide" element={<BlogPostStepByStepGuide />} />
        <Route path="/blog/odorless-tube-customer-stories" element={<BlogPostCustomerStories />} />
        <Route path="/blog/smoke-buddy-vs-odorless-tube" element={<BlogPostSmokeBuddyVsOdorlessTube />} />


              {/* New Blog Post Routes */}
      <Route path="/blog/common-household-odors" element={<BlogPostCommonHouseholdOdors />} />
      <Route path="/blog/diy-vs-professional-odor-removal" element={<BlogPostDIYvsProfessionalOdorRemoval />} />
      <Route path="/blog/top-air-quality-myths-debunked" element={<BlogPostTopAirQualityMythsDebunked />} />
      <Route path="/blog/pet-friendly-odor-free-home" element={<BlogPostPetFriendlyOdorFreeHome />} />
      <Route path="/blog/environmental-benefits-activated-carbon" element={<BlogPostEnvironmentalBenefitsActivatedCarbon />} />
      <Route path="/blog/odorless-tube-faq" element={<BlogPostOdorlessTubeFAQ />} />
      <Route path="/blog/reducing-smoke-odor-small-apartments" element={<BlogPostReducingSmokeOdorSmallApartments />} />
        
      </Routes>


      {/* Footer */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <AppBar position="static" color="primary" sx={{ marginTop: 8 }}>
          <Container maxWidth="lg">
            <Toolbar sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Typography variant="body1" color="inherit">
                © {new Date().getFullYear()} SmokeAwaySolutions. All rights reserved.
              </Typography>
              <Box>
                <Typography variant="body1" component="span" color="inherit">
                  Email us:{" "}
                  <MuiLink
                    href="mailto:SmokeAwaySolutions@gmail.com"
                    color="inherit"
                    underline="hover"
                  >
                    SmokeAwaySolutions@gmail.com
                  </MuiLink>
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" component="span" color="inherit">
                  Follow us:
                </Typography>
                <Tooltip title="Youtube" arrow>
                  <IconButton
                    component="a"
                    href="https://www.youtube.com/@SmokeAwaySolutions/"
                    color="inherit"
                    sx={{ marginLeft: 1 }}
                    aria-label="Youtube"
                  >
                    <YoutubeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Instagram" arrow>
                  <IconButton
                    component="a"
                    href="https://www.instagram.com/smokeawaysolutions/"
                    color="inherit"
                    sx={{ marginLeft: 1 }}
                    aria-label="Instagram"
                  >
                    <InstagramIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Facebook" arrow>
                  <IconButton
                    component="a"
                    href="https://www.facebook.com/p/smoke-away-solutions-61568308558660/"
                    color="inherit"
                    sx={{ marginLeft: 1 }}
                    aria-label="Facebook"
                  >
                    <FacebookIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>

              {/* Footer Links */}
<Toolbar sx={{ justifyContent: 'center' }}>
  <MuiLink
    component="button"
    onClick={() => handleNavItemClick('/privacy-policy')}
    color="inherit"
    sx={{ marginX: 1 }}
    underline="hover"
  >
    Privacy Policy
  </MuiLink>
  <MuiLink
    component="button"
    onClick={() => handleNavItemClick('/terms-of-service')}
    color="inherit"
    sx={{ marginX: 1 }}
    underline="hover"
  >
    Terms of Service
  </MuiLink>
  <MuiLink
    component="button"
    onClick={() => handleNavItemClick('/contact-us')}
    color="inherit"
    sx={{ marginX: 1 }}
    underline="hover"
  >
    Contact Us
  </MuiLink>
  <MuiLink
    component="button"
    onClick={() => handleNavItemClick('/blog')} // Add this line
    color="inherit"
    sx={{ marginX: 1 }}
    underline="hover"
  >
    Blog
  </MuiLink>
</Toolbar>

          </Container>
        </AppBar>
      </motion.div>

      {/* Back to Top */}
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <ScrollToTop />
        <AppContent {...props} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
