// BlogPostStepByStepGuide.js

import React from 'react';
import { motion } from 'framer-motion';
import { Container, Typography, Box, Link, List, ListItem, ListItemText } from '@mui/material';
import { Helmet } from 'react-helmet';

import imageCompressed from '../../assets/images/product-1.jpg'
import imageCompressed2 from '../../assets/images/product-2.jpg'
import imageTubeNoBG from '../../assets/images/tube_no_background.png'
import imageTubeRemder from '../../assets/images/odorless-tube-render.png'



export function BlogPostStepByStepGuide() {
  return (
    <Container maxWidth="md" sx={{ paddingY: 8 }}>
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>A Step-by-Step Guide to Using the Odorless Tube Effectively | SmokeAwaySolutions</title>
        <meta
          name="description"
          content="Learn how to effectively use the Odorless Tube to eliminate smoke odors from cigarettes and cannabis. Follow our step-by-step guide for optimal indoor air quality."
        />
      </Helmet>

      {/* Blog Title */}
      <Typography variant="h4" component="h1" gutterBottom>
        A Step-by-Step Guide to Using the Odorless Tube Effectively
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Maintaining a fresh and odor-free indoor environment is essential for your comfort and well-being, especially if you enjoy smoking or vaping indoors. Traditional methods like air fresheners and ventilation systems often fall short in completely eliminating smoke odors. Enter the <strong>Odorless Tube</strong> by <strong>SmokeAwaySolutions</strong>—a revolutionary device designed to efficiently remove smoke odors, ensuring a pleasant and inviting atmosphere in any space. Whether you're vaping in your home office or smoking near a window, this guide will help you maximize the effectiveness of your Odorless Tube.
      </Typography>

      <Box sx={{ textAlign: 'center', my: 4 }}>
        <img src={imageTubeRemder} alt="Activated Carbon Structure" style={{ maxWidth: '50%', height: 'auto', borderRadius: "30px" }} />
      </Box>

      {/* Section 1: Setting Up Your Odorless Tube */}
      <Typography variant="h5" component="h2" gutterBottom>
        1. Setting Up Your Odorless Tube
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Unboxing and Initial Setup
      </Typography>
      <Typography variant="body1" paragraph>
        Start by unboxing your Odorless Tube and familiarizing yourself with its components:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Dual-Fan Suction System: Efficiently draws in smoky air." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Activated Carbon Filters: Neutralize odors at the molecular level." />
        </ListItem>
        <ListItem>
          <ListItemText primary="USB-A Powered Fans: Provides flexibility in placement." />
        </ListItem>
      </List>
      <Typography variant="h6" component="h3" gutterBottom>
        Placement for Optimal Performance
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>For Vaping</strong>: Place the Odorless Tube on your sofa, desk, or any convenient spot in your home office. Its portable design allows for easy positioning without obstructing your space.<br />
        <strong>For Smoking</strong>: Position the device near a window to facilitate the expulsion of odor-free air outside. Ensure the tube is directed towards the window to maximize airflow.
      </Typography>
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <img
          src={imageTubeNoBG}
          alt="Odorless Tube Flexible Setup"
          style={{ maxWidth: '40%', height: 'auto', borderRadius: '30px' }}
        />
        <Typography variant="caption">Odorless Tube Setup Diagram</Typography>
      </Box>

      {/* Section 2: Features of the Odorless Tube */}
      <Typography variant="h5" component="h2" gutterBottom>
        2. Features of the Odorless Tube
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Portability and Compactness
      </Typography>
      <Typography variant="body1" paragraph>
        The Odorless Tube is designed with portability in mind. It can be easily compressed to around <strong>30 centimeters in length</strong>, making it highly portable and easy to store. Whether you need to move it between rooms or take it on the go, its compact design ensures it doesn't take up unnecessary space.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Extendable and Compressible Design
      </Typography>
      <Typography variant="body1" paragraph>
        For scenarios requiring extended functionality, the Odorless Tube can be folded out to a length of <strong>2 meters</strong>. This extendable feature allows for dynamic functioning in various situations, providing optimal airflow and odor elimination regardless of the environment. Whether you're setting it up in a large living area or a confined workspace, the Odorless Tube adapts to meet your needs.
      </Typography>
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <img
          src={imageCompressed2}
          alt="Extendable and Compressible Design"
          style={{ maxWidth: '70%', height: 'auto', borderRadius: '30px' }}
        />
        <Typography variant="caption">Extendable and Compressible Design</Typography>
      </Box>

      {/* Section 3: How to Use the Odorless Tube */}
      <Typography variant="h5" component="h2" gutterBottom>
        3. How to Use the Odorless Tube
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Step-by-Step Instructions
      </Typography>
      <List component="ol">
        <ListItem>
          <ListItemText primary="Connect the Fans: Plug the USB-A cords into a power source, such as a USB port on your computer or a USB power bank. For maximum efficiency, use both cords." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Position the Tube: Place the Odorless Tube on your sofa or desk, ensuring it's close enough to where you vape or smoke." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Activate the Device: Turn on the fans to start the dual-fan suction system. The device will begin to draw in the smoky air, passing it through the activated carbon filters." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Monitor Airflow: Ensure that the airflow is smooth and consistent. Adjust the position if necessary to cover the entire area." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        <strong>Benefits:</strong>
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Discreet Operation: Its compact size allows for easy placement without drawing attention." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Enhanced Air Quality: Removes not only smoke odors but also reduces airborne particles, contributing to a healthier environment." />
        </ListItem>
      </List>
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <motion.div
          whileHover={{ scale: 1.05 }}
          transition={{ type: 'spring', stiffness: 300 }}
          style={{
            position: 'relative',
            borderRadius: '20px',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '600px', // Ensure the container has a max width
            paddingTop: '56.25%', // 16:9 Aspect Ratio
          }}
        >
         <iframe
                        src={`https://www.youtube.com/embed/Jz_BMoTWcIw?autoplay=1&loop=1&playlist=Jz_BMoTWcIw&mute=1&controls=0&modestbranding=1&rel=0&showinfo=0`}
                        title="How It Works Video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '80%',
                          borderRadius: '20px',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                        }}
                      ></iframe>
          {/* Optional Overlay */}
          <motion.div
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 0.2 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#000',
              borderRadius: '20px',
              pointerEvents: 'none', // Allows interactions with the video
            }}
          />
        </motion.div>
        <Typography variant="caption">Odorless Tube Demonstration Video</Typography>
      </Box>

      {/* Section 4: Additional Precautions for Maximum Efficiency */}
      <Typography variant="h5" component="h2" gutterBottom>
        4. Additional Precautions for Maximum Efficiency
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Using Curtains as a Smoke Barrier
      </Typography>
      <Typography variant="body1" paragraph>
        To further enhance the effectiveness of your Odorless Tube when smoking, consider using thick curtains:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Install Near the Tube: Hang the curtains around the window where the tube is placed." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Create a Sealed Environment: This setup ensures that smoke is directed into the Odorless Tube rather than spreading throughout the room." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Easy to Clean: Choose curtains that are easy to wash, maintaining a clean and fresh appearance." />
        </ListItem>
      </List>


      <Typography variant="h6" component="h3" gutterBottom>
        Regular Maintenance
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Filter Replacement</strong>: Replace the activated carbon filters every 2-3 months, depending on usage, to ensure optimal performance.<br />
        - <strong>Cleaning the Device</strong>: Regularly clean the Odorless Tube to prevent buildup and maintain airflow efficiency.
      </Typography>

      {/* Section 5: Troubleshooting Common Issues */}
      <Typography variant="h5" component="h2" gutterBottom>
        5. Troubleshooting Common Issues
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        Issue 1: Weak Airflow
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Solution</strong>: Ensure the USB-A cord is properly connected and the fans are functioning. Check for any blockages in the tube.
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        Issue 2: Persistent Odors
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Solution</strong>: Replace the activated carbon filters if they haven't been changed recently. Ensure the device is positioned correctly near the source of smoke.
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        Issue 3: Device Not Powering On
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Solution</strong>: Verify that the USB cords are securely plugged into a power source. Try using a different USB port or power bank.
      </Typography>


      {/* Conclusion */}
      <Typography variant="h5" component="h2" gutterBottom>
        Conclusion
      </Typography>
      <Typography variant="body1" paragraph>
        The <strong>Odorless Tube</strong> by <strong>SmokeAwaySolutions</strong> offers a simple yet highly effective solution for eliminating smoke odors, whether you're vaping or smoking indoors. By following this step-by-step guide, you can ensure that your living or working space remains fresh and odor-free. Experience the convenience and efficiency of the Odorless Tube and enjoy a healthier, more pleasant indoor environment today!
      </Typography>

      {/* Call to Action */}
      <Box sx={{ textAlign: 'center', marginTop: 6 }}>
        <Link href="https://smokeawaysolutions.com/" underline="none">
          <Box
            sx={{
              backgroundColor: '#ffb300',
              color: '#fff',
              paddingY: 1.5,
              paddingX: 4,
              borderRadius: 2,
              display: 'inline-block',
              '&:hover': {
                backgroundColor: '#FF9422',
              },
            }}
          >
            Buy Now for $99
          </Box>
        </Link>
      </Box>

      {/* References */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          References:
        </Typography>
        <List>
          <ListItem>
            <Link href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9866597/" target="_blank" rel="noopener" underline="hover">
              Adsorption Capacity and Desorption Efficiency of Activated Carbon for Odors from Medical Waste
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://pubmed.ncbi.nlm.nih.gov/18388369/" target="_blank" rel="noopener" underline="hover">
              Activated charcoal and baking soda to reduce odor associated with extensive blistering disorders
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://pubmed.ncbi.nlm.nih.gov/25112027/" target="_blank" rel="noopener" underline="hover">
              Efficient control of odors and VOC emissions via activated carbon technology
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://pubmed.ncbi.nlm.nih.gov/14587955/" target="_blank" rel="noopener" underline="hover">
              Odor and volatile organic compound removal from wastewater treatment plant headworks ventilation air using a biofilter
            </Link>
          </ListItem>
        </List>
      </Box>

      {/* Footer */}
      <Box sx={{ marginTop: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          © 2024 SmokeAwaySolutions. All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email us: <Link href="mailto:SmokeAwaySolutions@gmail.com" underline="hover">SmokeAwaySolutions@gmail.com</Link>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Follow us:
          {/* Add social media links/icons here */}
        </Typography>
      </Box>
    </Container>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  title: {
    fontSize: '2.5em',
    marginBottom: '20px',
    color: '#333',
  },
  intro: {
    fontSize: '1.2em',
    marginBottom: '20px',
  },
  subheading: {
    fontSize: '1.8em',
    marginTop: '30px',
    marginBottom: '15px',
    color: '#555',
  },
  subsubheading: {
    fontSize: '1.4em',
    marginTop: '20px',
    marginBottom: '10px',
    color: '#666',
  },
  paragraph: {
    marginBottom: '15px',
    color: '#444',
  },
  list: {
    marginBottom: '15px',
    paddingLeft: '20px',
    color: '#444',
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: '20px',
    borderRadius: '30px',
  },
  video: {
    width: '100%',
    height: 'auto',
    marginBottom: '20px',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
  },
  cta: {
    textAlign: 'center',
    marginTop: '30px',
  },
  button: {
    backgroundColor: '#ffb300',
    color: '#fff',
    paddingY: '15px',
    paddingX: '25px',
    borderRadius: '5px',
    fontSize: '1.2em',
    textDecoration: 'none',
    display: 'inline-block',
    '&:hover': {
      backgroundColor: '#FF9422',
    },
  },
};
