// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCE0ywmireOe-7gFvEy9ohFzelxpH4yc1U",
  authDomain: "smokeawaysolutions-e6ccb.firebaseapp.com",
  projectId: "smokeawaysolutions-e6ccb",
  storageBucket: "smokeawaysolutions-e6ccb.firebasestorage.app",
  messagingSenderId: "735690653258",
  appId: "1:735690653258:web:e9bf2f236706d05ec6f0b3",
  measurementId: "G-EW290CP2WL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app); // Add this line to initialize Firestore

// Function to add email
export const addEmail = async (formData) => {
  try {
    await addDoc(collection(db, "email"), formData);
    return { success: true, message: 'Email submitted successfully' };
  } catch (error) {
    console.error("Error adding email document: ", error);
    return { success: false, message: error.message };
  }
};

// Function to add contact us data
export const addContactUsData = async (formData) => {
  try {
    await addDoc(collection(db, "contact"), formData);
    return { success: true, message: 'Contact us data submitted successfully' };
  } catch (error) {
    console.error("Error adding contact us document: ", error);
    return { success: false, message: error.message };
  }
};
